/**
 * External Dependencies
 */

import 'jquery'

import Router from './router.js'

import common from './routes/common.js'
import home from './routes/home.js'
import product from './routes/product.js'

/** Populate Router instance with DOM routes */
const routes = new Router({
  common,
  home,
  product,
});


$(document).ready(() => {
    routes.load();
})
