import Swiper, { Pagination, Autoplay } from 'swiper';

Swiper.use([Pagination, Autoplay]);


export default {
    sliderHero() {
        $('.hero-home').each(function() {
            if($('.hero-home').find('.swiper-slide').length > 1){
                new Swiper(this, {
                    speed: 600,
                    loop: true,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                })
            }

        })
    },
    init(){
        this.sliderHero()
    },
    finalize() {
    },
}
