import LazyLoad from 'vanilla-lazyload'
import Swiper, { Navigation } from 'swiper'
import fitty from 'fitty'
import chunk from 'lodash/chunk'

Swiper.use([Navigation]);

export const lazyLoadInstance = new LazyLoad({
  elements_selector: '.data-load-img',
  use_native: true,
})

export default {
    menuHeader(){

function waitEnterExit(el, inside, time = 250) {
  var button = $(el);

  setTimeout(function() {
    var hovered = button.is(':hover');

    if (hovered && inside)
      button.children('ul.submenu').addClass('active-lg');
    else if (!hovered && !inside)
      button.children('ul.submenu').removeClass('active-lg');
  }, time);
}
$('.nav-primary__li').on({
  mouseenter: function() {
    waitEnterExit(this, true, 150);
  },
  mouseleave: function() {
    waitEnterExit(this, false);
  },
});


function waitEnterExitChild(el, inside, time = 400) {
  var button = $(el);

  setTimeout(function() {
    var hovered = button.is(':hover');

    if (hovered && inside)
      button.children('ul.submenu_sub').addClass('active-lg');
    else if (!hovered && !inside)
      button.children('ul.submenu_sub').removeClass('active-lg');
  }, time);
}
$('.submenu__li').on({
  mouseenter: function() {
    waitEnterExitChild(this, true, 150);
  },
  mouseleave: function() {
    waitEnterExitChild(this, false);
  },
});

// var timerParent;
// $('.nav-primary__li').on({
//     mouseenter: function(){
//         var self = this;
//         clearTimeout(timerParent);
//         timerParent = setTimeout(function(){
//             $(self).find('ul.submenu').addClass('active-lg');
//         }, 300)
//     },
//     mouseleave: function(){
//         var self = this;
//         setTimeout(function(){
//             if (self.querySelector("ul.submenu:hover") == null){
//                  $(self).find('ul.submenu').removeClass('active-lg');
//             }
//         }, 300);
//     }
// });

// var timerChild;
// $('.submenu__li').on({
//     mouseenter: function(){
//         var self = this;
//         clearTimeout(timerChild);
//         timerChild = setTimeout(function(){
//             $(self).find('ul.submenu_sub').addClass('active-lg');
//         }, 300)
//     },
//     mouseleave: function(){
//         var self = this;
//         setTimeout(function(){
//             if (self.querySelector("ul.submenu_sub:hover") == null){
//                  $(self).find('ul.submenu_sub').removeClass('active-lg');
//             }
//         }, 300);
//     }
// });




        // var currentParent = null
        // $(document.body).on('mouseenter', '.nav-primary__li', function(){
        //     if($(this).find('ul.submenu')){
        //         $(this).find('ul.submenu').addClass('active-lg')
        //         currentParent = $(this)
        //     }
        // })
        // $(document.body).on('mouseleave', '.nav-primary__li', function(){
        //     if($(this).find('ul.submenu')){
        //         // setTimeout(() => {
        //         //     $(this).find('ul.submenu').removeClass('active-lg')
        //         // }, 150)

        //     }
        // })

    },
    fitText(){

        $(".fittext").each(function(){
            fitty(this)
        })

        // $('.block-title, .product-title, .hero-title, .title-page, .text-title-home').each(function(){
        //     $(this).find('span').each(function(){
        //         if(!$(this).text().indexOf('genisys') == 0){
        //             $(this).addClass('uppercase')
        //         }
        //     })
        // })

    },
    splitLines() {
        const app = this
        $('[data-split-title]').each(function(){
            const splitLines = $(this).html().split(' ')
            const filtered = splitLines.filter(function (el) {
                if(el == '' || el == '-' || el == ' '){
                    return false;
                }
                return el;
            })
            let finalhtml = ''
            const finalA = chunk(filtered, parseInt(filtered.length / $(this).attr('data-split-title')))
            finalA.map(itm => {
                finalhtml += '<span class="fittext">' + itm.join(' ') +'</span>'
            })
            $(this).html(finalhtml)
            app.fitText()


        })
        $('[data-split]').each(function(){
            const splitLines = $(this).html().split(' ')
            const filtered = splitLines.filter(function (el) {
                if(el == '' || el == '-' || el == ' '){
                    return false;
                }
                return el;
            })
            let finalhtml = ''
            if(filtered.length <= 1){
                if(filtered[0]){
                    $(this).html('<span>' + filtered[0]+'</span>')
                }
            }
            else if(filtered.length === 2){
                finalhtml += '<span>' + filtered[0] +'</span>'
                finalhtml += '<span>' + filtered[1] +'</span>'
                $(this).html(finalhtml)
            }
            else if(filtered.length === 3){
                finalhtml += '<span>' + filtered[0] +'</span>'
                finalhtml += '<span>' + filtered[1] +'</span>'
                finalhtml += '<span>' + filtered[2] +'</span>'
                $(this).html(finalhtml)
            }
            else {
                finalhtml += '<span>' + filtered[0] +'</span>'


                filtered.shift()
                const finalA = chunk(filtered, parseInt(filtered.length / $(this).attr('data-split')))

                finalA.map(itm => {
                    finalhtml += '<span>' + itm.join(' ') +'</span>'
                })
                 $(this).html(finalhtml)
            }


        })

    },
    smoothScroll(){
        $(document).on('click', '.smooth', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top -100
            }, 500);
        });
    },

    sliderCommon( ){

        $('.slider-products-common').each(function() {
            if($(this).find('.swiper-slide').length > 4){
                new Swiper(this, {
                    speed: 600,
                    slidesPerView: 'auto',
                    loop: false,
                    watchSlidesVisibility: true,
                    spaceBetween: 15,
                    watchOverflow: true,
                    navigation: {
                        nextEl: $(this).find('.swiper-button-next').get(0),
                        prevEl: $(this).find('.swiper-button-prev').get(0),
                    },
                })
            }
            else {
                $(this).find('.swiper-button-next').remove()
                $(this).find('.swiper-button-prev').remove()
                $(this).find('.swiper-wrapper').addClass('justify-center flex-wrap -mx-3')
                $(this).find('.swiper-slide').addClass('px-3 my-3')
            }

        })
    },
    tabsLoad(){
        $('.tabs-section ul.tabs').addClass('active').find('> li:eq(0) a').addClass('current');

        $('.tabs-section ul.tabs li a').click(function (g) {
            var tab = $(this).closest('.tabs-section'),
                index = $(this).closest('li').index();

            tab.find('ul.tabs > li a').removeClass('current');
            $(this).closest('li a').addClass('current');

            tab.find('.tab-content').find('div.tabs-item').not('div.tabs-item:eq(' + index + ')').slideUp();
            tab.find('.tab-content').find('div.tabs-item:eq(' + index + ')').slideDown();

            g.preventDefault();
        } );
    },
    sliderHorizontal() {
        $('.slider-horizontal').each(function() {
            const nextEl = $(this).closest('.section-slider-horizontal').find('.slider-horizontal__nav--next').get(0)
            const prevEl = $(this).closest('.section-slider-horizontal').find('.slider-horizontal__nav--prev').get(0)
            new Swiper(this, {
                speed: 600,
                slidesPerView: 2,
                loop: false,
                watchSlidesVisibility: true,
                spaceBetween: 15,
                navigation: {
                    nextEl: nextEl,
                    prevEl: prevEl,
                },
            })
        })
    },
    hamburgerMenu() {
        $('header .nav-primary').toggleClass('opened')
        $(document.body).toggleClass('overflow-hidden')
    },
  iconBurger() {
    const App = this
    function t(i) {
        var e = i.parentNode;
        if ('svg' !== e.tagName) e = t(e);
        return e;
    }

    function i(t, i, e, n) {
        return 1 > (t /= n / 2) ? e / 2 * t * t * t * t + i : -e / 2 * ((t -= 2) * t * t * t - 2) + i
    }

    function e(t) {
        this.element = t, this.topLine = this.element.getElementsByTagName('path')[0], this.bottomLine = this.element.getElementsByTagName('path')[1], this.time = {
            start: null,
            total: 250,
        }, this.status = {
            interacted: !1,
            animating: !1,
        }, this.init()
    }

    if (!window.requestAnimationFrame) {
        var n = null;
        window.requestAnimationFrame = function(t) {
            var e = (new Date).getTime();
            n || (n = e);
            var a = Math.max(0, 16 - (e - n)),
                s = window.setTimeout(function() {
                    t(e + a)
                }, a);
            return n = e + a, s
        }
    }

    e.prototype.init = function() {
          var t = this;

            this.element.addEventListener('click', function() {
            if(!t.status.animating){
              App.hamburgerMenu()
              window.requestAnimationFrame(t.triggerAnimation.bind(t))
            }
            })



    }, e.prototype.triggerAnimation = function(t) {
        var i = this.getProgress(t),
            e = this.status.interacted ? this.time.total - i : i;
        this.animateIcon(e), this.checkProgress(i)
    }, e.prototype.getProgress = function(t) {
        return this.time.start || (this.time.start = t), t - this.time.start
    }, e.prototype.checkProgress = function(t) {
        var i = this;
        this.time.total > t ? window.requestAnimationFrame(i.triggerAnimation.bind(i)) : (this.status = {
            interacted: !this.status.interacted,
            animating: !1,
        }, this.time.start = null)
    }, e.prototype.animateIcon = function(t) {
        if (t > this.time.total)(t = this.time.total);
        if (0 > t)(t = 0);
        var e = i(Math.min(t, this.time.total / 2), 0, 7, this.time.total / 2).toFixed(2),
            n = i(Math.max(t - this.time.total / 2, 0), 0, 45, this.time.total / 2).toFixed(2);
        this.topLine.setAttribute('transform', 'translate(0 ' + e + ') rotate(' + n + ' 16 9)'), this.bottomLine.setAttribute('transform', 'translate(0 ' + -e + ') rotate(' + -n + ' 16 23)')
    };
    var a = document.getElementsByClassName('vital-menu-svg');
    if (a)
        for (var s = 0; a.length > s; s++) new e(t(a[s]))
  },

    init() {
        this.iconBurger()
    },
    finalize() {
        this.tabsLoad()
        this.sliderHorizontal()
        this.sliderCommon()
        this.smoothScroll()
        this.splitLines()
        this.fitText()
        this.menuHeader()
    },
};
