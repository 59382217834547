
import Swiper, { Thumbs, Navigation, Zoom } from 'swiper';
import Tablesaw from 'tablesaw';


Swiper.use([ Thumbs, Navigation, Zoom ]);


export default {
    tabsLoad() {
        $('[data-tab]').each(function(){
            $('.tabs').append('<li><a href="#">' + $(this).attr('data-tab') + '</a></li>')
        })
        $('.tab-product ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');

        $(document.body).on('click','.tab-product ul.tabs li a', function (g) {
            var tab = $(this).closest('.tab-product'),
                index = $(this).closest('li').index();

            tab.find('ul.tabs > li').removeClass('current');
            $(this).closest('li').addClass('current');

            tab.find('.tab_content').find('div.tabs_item').not('div.tabs_item:eq(' + index + ')').slideUp();
            tab.find('.tab_content').find('div.tabs_item:eq(' + index + ')').slideDown();

            g.preventDefault();
        } );

    },
    sliderProduct() {
        $('.swiper-zoom').each(function(){
            return new Swiper(this, {
                resizeObserver: true,
                speed: 600,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                zoom: {
                    maxRatio: 2,
                }
            })


        })
        $('.slider-main').each(function() {
            let thumbsconfig = null
            let swiperthumbs = null
            if($(this).find('.slider-thumbs').find('.swiper-slide').length > 1){
                let swiperthumbs = new Swiper($(this).find('.slider-thumbs').get(0), {
                    speed: 600,
                    resizeObserver: true,
                    direction: 'vertical',
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    centerInsufficientSlides: true,
                    slideToClickedSlide: true,
                })

                thumbsconfig = {
                    swiper: swiperthumbs,
                    autoScrollOffset: 1,
                    multipleActiveThumbs: false,
                }

            } else {
                $(this).find('.slider-thumbs').addClass('!hidden')
                $(this).addClass('!pl-0').addClass('!lg:pl-0')
            }


            const bigslider = new Swiper($(this).find('.slider-big').get(0), {
                speed: 600,
                loop: true,
                lazy: true,
                resizeObserver: true,
                thumbs: thumbsconfig
            })

        })
    },
    tablesawInit() {
        $('.fill-table-data').each(function(){
            $(this).find('.element').each(function(index ){
                const text = $(this).text()
                if($('[fill-data-table] tbody').find('tr').get(index)){
                    $($('[fill-data-table] tbody').find('tr').get(index)).append('<td class="border-dashed lg:border-r border-black">' + text + '</td>')
                }
            })
            $(this).remove()
        })
        $('.responsive-table').find('input[type="hidden"]').remove()

        $('.responsive-table').each(function(){
            var that = this;
            $(that).attr('data-tablesaw-mode','stack');
        });
        setTimeout(function(){
            Tablesaw.init();
        },500);


    },
    init(){
       this.sliderProduct()
        this.tabsLoad()
        this.tablesawInit()
    },
    finalize() {
        $(window).on('scroll', function(){
            $(window).trigger('resize');
        })


    },
}
